<template>
  <AppWrapper>
    <div class="app__products">
      <div class="app__products--header">
        <h4>Let's get you <span>powered</span>, now.</h4>
        
        <div class="app__products--header__paragraph">
          <p>Not sure what you need? We've made it simple. Select</p>
          <p>from our combination of inverters, batteries and solar</p>
          <p>panels, to find a plan that fits your needs;</p>
        </div>
      </div>

      <div class="app__products--toggle">
        <input type="checkbox" id="switch" v-model="showWithPanel" @click="withPanel(showWithPanel)" />

        <label for="switch">
          <p class="with">WITH PANELS</p>
          <p class="without">WITHOUT PANELS</p>
          <span class="ball"></span>
        </label>
      </div>

      <div class="app__products--items" v-if="isLoading">
        <div
          class="app__products--items__box"
          aria-haspopup="true"
          v-for="num in 5"
          v-bind:key="num"
        >
          <SkeletonLoader size="50" />

          <div class="app__products--items__box--product">
            <SkeletonLoader size="100" />
            <SkeletonLoader size="100" />
            <div class="app__products--items__box--product__items">
              <SkeletonLoader size="100" />
            </div>
          </div>

          <div class="app__products--items__box--price">
            <div class="app__products--items__box--price__monthly">
              <SkeletonLoader size="100" />

            </div>

            <div>
              <SkeletonLoader size="50" />
            </div>

            <div class="app__products--items__box--price__outright">
              <SkeletonLoader size="100" />
            </div>
          </div>
        </div>
      </div>

      <div class="app__products--items" v-else>
        <div
          class="app__products--items__box"
          aria-haspopup="true"
          v-for="product, in products"
          v-bind:key="product.id"
        >
          <div class="app__products--items__box--flex">
            <span class="app__products--items__box--flex__badge">{{ product.specification }}</span>

            <a @click="getProductComponents(product)">View Specs</a>
          </div>

          <div class="app__products--items__box--product">
            <h5>{{ product.productName }}</h5>
            <p>{{ truncate(product.description, 40) }}</p>
            <div class="app__products--items__box--product__items">
              <template v-for="(item, i) in product.items" v-bind:key="`${item}-${i}`">
                <p>{{ item }}</p>

                <span v-if="i !== (product.items.length - 1)"></span>
              </template>
            </div>
          </div>

          <div class="app__products--items__box--price">
            <div class="app__products--items__box--price__monthly">
              <h4><span>₦{{ getProductPrice(product.repaymentAmount) }}</span> monthly</h4>
              <p>₦{{ getProductPrice(product.initialPayment) }} down payment</p>
            </div>

            <div class="app__products--items__box--price__or">
              <p>OR</p>
            </div>

            <div class="app__products--items__box--price__outright">
              <div class="app__products--items__box--price__outright--text">
                <h4>₦{{ getProductPrice(product.totalPrice) }}</h4>
                <p>outright payment</p>
              </div>
              <button @click="goToApp">get quote</button>
            </div>
          </div>
        </div>
      </div>

      <div class="app__products--overflow">
        <div class="app__products--overflow__gradient"></div>
        
        <div class="app__products--overflow--items-large" v-if="isLoading">
          <div
            class="app__products--overflow--items-large__box"
            aria-haspopup="true"
            v-for="num in 5" :key="num"
          >
            <SkeletonLoader size="50" />

            <div class="app__products--overflow--items-large__box--product">
              <SkeletonLoader size="100" />
              <SkeletonLoader size="100" />
              <div class="app__products--overflow--items-large__box--product__items">
                <SkeletonLoader size="100" />
              </div>
            </div>

            <div class="app__products--overflow--items-large__box--price">
              <div class="app__products--overflow--items-large__box--price__monthly">
                <SkeletonLoader size="100" />
              </div>

              <div>
                <SkeletonLoader size="50" />
              </div>

              <div class="app__products--overflow--items-large__box--price__outright">
                <SkeletonLoader size="100" />
              </div>
            </div>
          </div>
        </div>

        <div class="app__products--overflow--items-large" v-else>
          <div
            class="app__products--overflow--items-large__box"
            aria-haspopup="true"
            v-for="product in products" :key="product.id"
          >
            <div class="app__products--overflow--items-large__box--flex">
              <span class="app__products--overflow--items-large__box--flex__badge">
                {{ product.specification }}
              </span>

              <a @click="getProductComponents(product)">View Specs</a>
            </div>

            <div class="app__products--overflow--items-large__box--product">
              <h5>{{ product.productName }}</h5>
              <p>{{ truncate(product.description, 40) }}</p>
              <div class="app__products--overflow--items-large__box--product__items">
                <template v-for="(item, i) in product.items" v-bind:key="`${item}-${i}`">
                  <p>{{ item }}</p>

                  <span v-if="i !== (product.items.length - 1)"></span>
                </template>
              </div>
            </div>

            <div class="app__products--overflow--items-large__box--price">
              <div class="app__products--overflow--items-large__box--price__monthly">
                <h4><span>₦{{ getProductPrice(product.repaymentAmount) }}</span> monthly</h4>
                <p>₦{{ getProductPrice(product.initialPayment) }} down payment</p>
              </div>

              <div class="app__products--overflow--items-large__box--price__or">
                <p>OR</p>
              </div>

              <div class="app__products--overflow--items-large__box--price__outright">
                <div class="app__products--overflow--items-large__box--price__outright--text">
                  <h4>₦{{ getProductPrice(product.totalPrice) }}</h4>
                  <p>outright payment</p>
                </div>
                <button @click="goToApp">get quote</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="app__products--info">
        <div class="app__products--info__badge">
          <img src="@/assets/info.svg" alt="info" />
          <p>All plans come with a 12 month warranty</p>
        </div>
      </div>

      <ViewSpecifications
        :product="productModalData"
        :modalDisplay="modalDisplay"
        @close="closeModal"
      />
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed, ref, onMounted } from "vue";
import axios from 'axios';
import { useFormatFiguresToCurrency } from "@/composables/currencyFormater";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import ViewSpecifications from "@/components/ViewSpecifications.vue";
import { event } from 'vue-gtag'

const isLoading = ref(true);
const showWithPanel = ref(false);
const allProducts = ref([]);
const withOrWithoutPanelProducts = ref([]);
const productModalData = ref([]);
const modalDisplay = ref("none");

const products = computed(() => {
  return withOrWithoutPanelProducts.value
});
const url = computed(() => process.env.VUE_APP_GREEN_ENERGY_WEB_APP_URL);

onMounted(() => {
  if (withOrWithoutPanelProducts.value.length === 0) {
    getAllProducts();
  }
});

const showModal = () => {
  modalDisplay.value = "block"
};
const closeModal = () => (modalDisplay.value = "none");

function truncate(str, length) {
  let sliceStr = str;

  if (str && str.length > length) {
    sliceStr = str.slice(0, length);

    if (sliceStr.split("|")[0].toLowerCase().includes("with")) {
      return sliceStr.split("|")[0] + "...";
    } else {
      return sliceStr.split(",")[0] + "...";
    }
  } else return str
}

const getProductPrice = (price) => {
  const { formattedCurrency } = useFormatFiguresToCurrency(price);

  return formattedCurrency.value;
}

function getWords(input, keyWord){
  //This is orkhan-alikhanov's answer commented below for shorter and single string search
  // input.split(',').filter(x => x.includes('laptop phone internet modem fan light bulb')).join(',')

  //This is the 'split' in orkhan-alikhanov's answer
  const stringVar= input?.split(",") ?? ""; // Take the input and turn it into an array, with each element being the words between ','

  let returnString=""; //create a string to concat all valid words into

  //For each word in that array, see if it has the word we are looking for, and if so, add it to the return string with a ',' character at the end
  //This is the 'Filter' in orkhan-alikhanov 's answer
  for(let i=0 ; i<stringVar.length; i++) {
    if(keyWord.includes(stringVar[i].toLowerCase())){
      //This is the 'join' in orkhan-alikhanov 's answer
      returnString = returnString + stringVar[i] + ",";
    }
  }

  //If we got even 1 result, remove the last ','
  if(returnString.length > 0){
    returnString = returnString.substring(0, returnString.length-1);
  }

  return returnString.split(".")[0].split(",");
}

function joinRelatedWords(listItems, relatedArr) {
  const list = []; // List to save related words

  // Map each related word in the array and join them together with space as separator
  for (let i = 0; i < relatedArr.length; i++) {
    const obj = {};
    obj[relatedArr[i].replace(/\s/g, "_")] = ""; //set the related word value as key to an object

    // Map through the list item and check if there is related words to join together
    for (let k = 0; k < listItems.length; k++) {
      if (relatedArr[i].includes(listItems[k].toLowerCase())) {
        obj[relatedArr[i].replace(/\s/g, "_")] += listItems[k] + " "
      }
    }

    list.push(obj[relatedArr[i].replace(/\s/g, "_")].trim()) // push the related word as single word into the list arr and trim for extra spaces
  }

  // filter out the related word from the list items arr and return a new arr with the filtered list items and the arr of related words
  return [...listItems.filter(word => !relatedArr.join(" ").includes(word.toLowerCase())), ...list];
}

function getProductItems(items, spec) {
  switch(spec.toLowerCase()) {
    case "1kva":
      return joinRelatedWords(
        getWords(items, "laptop phone internet modem fan light bulbs."),
        ["internet modem", "light bulbs"]
      );
    case "1.7kva":
      return joinRelatedWords(
        getWords(items, "fans laptops phones internet modems tvs"),
        ["internet modems"]
      );
    case "2.5kva":
      return joinRelatedWords(
        getWords(items, "tvs laptops phones internet modems water dispenser"),
        ["internet modems", "water dispenser"]
      );
    case "3.5kva":
      return joinRelatedWords(
        getWords(items, "phones hps inverter ac laptops bulbs water dispenser"),
        ["hps inverter ac", "water dispenser"]
      );
    case "5kva":
      return joinRelatedWords(
        getWords(items, "laptops tvs hps inverter ac phones water dispenser"),
        ["hps inverter ac", "water dispenser"]
      );
    case "7.5kva":
      return joinRelatedWords(
        getWords(items, "water dispenser laptops phones hps inverter ac fans"),
        ["hps inverter ac", "water dispenser"]
      );
    case "10kva":
      return joinRelatedWords(
        getWords(items, "fridge freezer phones light bulbs water dispenser"),
        ["light bulbs", "water dispenser"]
      );
    
  }
}

const getProductComponents = (product) => {
  productModalData.value = product;
  showModal();
}

const goToApp = () => {
  event('GetQuoteBtn', { method: 'Send User to web app to create account and get quote.' });
  const anchor = document.createElement('a');
  
  anchor.href = url.value;
  anchor.target = "_blank";
  anchor.click();
}

const getAllProducts = () => {
  isLoading.value = true;

  axios.get(
    `${process.env.VUE_APP_GREEN_ENERGY_API_URL}Product/GetWebProducts?key=usdsfv9hdvv9090d90u09sdvj09`,
  )
    .then((data) => {
      allProducts.value = data.data
      isLoading.value = false;
      withPanel();
    })
    .catch((e) => {
      console.log("err", e);
      isLoading.value = false;
    });
};

const withPanel = (isWithPanel = true) => {
  const products = allProducts.value.filter(
    (product) => product.product.hasSolarPanel === isWithPanel
  );

  if (products.length) {
    const withPanelProducts = products.map((product) => {
      // Specification data
      const desc = product.product.description.split("|")[0].split(" ")
      desc.pop();
      const spec = desc.splice(0, 2);
      
      const prod = {
        productName: product.product.name,
        warranty: product.product.warrantyPeriod,
        description: product.product.description,
        contributionPercentage: product.product.contributionPecentage,
        id: product.product.id,
        totalPrice: product.product.oneOffPaymentCost,
        initialPayment: product.product.initialPayment,
        repaymentAmount: product.product.repaymentAmount,
        specification: spec.join(" "),
        showDescription: false,
        components: product.productComponents,
        items: getProductItems(
          product.product.description
            .split("|")?.[1]?.trim()?.replace(/\s/g, ",")?.split("(")?.[0]?.split(",")?.filter(word => word !== "")?.join(","),
          spec[0]
        ),
        componentIdToSwitch: product.componentIdToSwitch
      }

      return prod;
    });
    withOrWithoutPanelProducts.value = withPanelProducts;
  } else {
    withOrWithoutPanelProducts.value = [];
  }
}
</script>

<style scoped>
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>
