<template>
  <div
    id="myModal"
    class="app__modal"
    :style="{
      display: props.modalDisplay,
      top: props.modalDisplay == 'block' ? 0 : '-100%',
      opacity: props.modalDisplay == 'block' ? 1 : 0,
    }"
  >
    <div class="app__modal--content">
      <div class="app__modal--content__header">
        <div class="app__modal--content__header--container">
          <div class="app__modal--content__header--container__close">
            <span @click="closeModal">&times;</span>
          </div>

          <div class="app__modal--content__header--container__header">
            <div>
              <SkeletonLoader v-if="isLoading" size="100" />
              <h2 v-else>{{ productToview?.productName }}</h2>

              <SkeletonLoader
                v-if="isLoading"
                size="100"
                style="margin-top: 20px"
              />
              <p v-else>{{ productToview?.specification }}</p>

              <div class="app__modal--content__header--container__header--desc">
                <SkeletonLoader
                  v-if="isLoading"
                  size="100"
                  style="margin-top: 20px"
                />
                <p v-else>{{ productToview.description }}</p>
              </div>

              <div
                class="app__modal--content__header--container__header--badge"
                v-if="product"
              >
                <span
                  v-for="(item, index) in productToview?.items"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
            </div>
          </div>

          <div class="app__modal--content__header--container__content">
            <div class="app__modal--content__header--container__content--card">
              <div v-if="isLoading">
                <SkeletonLoader size="50" />

                <SkeletonLoader size="100" style="margin-top: 20px" />
              </div>
              <div v-else>
                <h3>
                  <span>
                    ₦{{
                      getProductPrice(productToview?.repaymentAmount)
                    }} </span
                  >&ensp;monthly for&ensp;<span style="font-size: 12px"
                    >12 months</span
                  >
                </h3>
                <p>
                  ₦{{ getProductPrice(productToview?.initialPayment) }} down
                  payment
                </p>
              </div>
            </div>

            <p
              class="app__modal--content__header--container__content--separator"
            >
              OR
            </p>

            <div class="app__modal--content__header--container__content--card">
              <div v-if="isLoading">
                <SkeletonLoader size="50" />

                <SkeletonLoader size="100" style="margin-top: 20px" />
              </div>
              <div v-else>
                <h2>₦{{ getProductPrice(productToview?.totalPrice) }}</h2>
                <p>outright payment</p>
              </div>
            </div>
          </div>

          <div
            v-if='productToview?.componentIdToSwitch !== null && productToview?.componentIdToSwitch !== ""'
            style="
              margin-top: 30px;
              display: flex;
              align-items: center;
              gap: 10px;
              font-family: Outfit;
            "
          >
            <div class="toggle">
              <input type="checkbox" v-model="switchBatteryType" />
              <label></label>
            </div>
            <h5>I want Lithium battery</h5>
          </div>

          <div class="app__modal--content__header--container__hardware">
            <hr />
            <h4>Hardware Specs</h4>
          </div>
        </div>
      </div>

      <div class="app__modal--content__body">
        <div v-if="isLoading">
          <SkeletonLoader size="100" />
        </div>
        <div class="app__modal--content__body--table" v-else>
          <div class="app__modal--content__body--table__header">
            <h4>#</h4>
            <h4>Hardware</h4>
            <h4>Quantity</h4>
          </div>

          <div
            :class="
              index % 2 == 0
                ? 'app__modal--content__body--table__content border_column'
                : 'app__modal--content__body--table__content'
            "
            v-for="(component, index) in productToview?.components"
            :key="index"
          >
            <p>{{ index + 1 }}</p>
            <p>{{ component.specification }}</p>
            <p>{{ component.quantity }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useFormatFiguresToCurrency } from "@/composables/currencyFormater";
import { ref, reactive, watch } from "vue";
import axios from "axios";
import SkeletonLoader from "@/components/SkeletonLoader.vue";

/* eslint-disable  no-unused-vars */
/* eslint-disable  no-undef */
const props = defineProps({
  product: { type: Object, required: true },
  modalDisplay: { type: String, required: true, default: "none" },
});
const emits = defineEmits(["close"]);

const content = ref("Some text in the Modal.. !!");

let switchBatteryType = ref(false);
let isLoading = ref(false);
let productToview = reactive(props.product);

const closeModal = () => emits("close");

function getWords(input, keyWord) {
  //This is orkhan-alikhanov's answer commented below for shorter and single string search
  // input.split(',').filter(x => x.includes('laptop phone internet modem fan light bulb')).join(',')

  //This is the 'split' in orkhan-alikhanov's answer
  const stringVar = input?.split(",") ?? ""; // Take the input and turn it into an array, with each element being the words between ','

  let returnString = ""; //create a string to concat all valid words into

  //For each word in that array, see if it has the word we are looking for, and if so, add it to the return string with a ',' character at the end
  //This is the 'Filter' in orkhan-alikhanov 's answer
  for (let i = 0; i < stringVar.length; i++) {
    if (keyWord.includes(stringVar[i].toLowerCase())) {
      //This is the 'join' in orkhan-alikhanov 's answer
      returnString = returnString + stringVar[i] + ",";
    }
  }

  //If we got even 1 result, remove the last ','
  if (returnString.length > 0) {
    returnString = returnString.substring(0, returnString.length - 1);
  }

  return returnString.split(".")[0].split(",");
}

function joinRelatedWords(listItems, relatedArr) {
  const list = []; // List to save related words

  // Map each related word in the array and join them together with space as separator
  for (let i = 0; i < relatedArr.length; i++) {
    const obj = {};
    obj[relatedArr[i].replace(/\s/g, "_")] = ""; //set the related word value as key to an object

    // Map through the list item and check if there is related words to join together
    for (let k = 0; k < listItems.length; k++) {
      if (relatedArr[i].includes(listItems[k].toLowerCase())) {
        obj[relatedArr[i].replace(/\s/g, "_")] += listItems[k] + " ";
      }
    }

    list.push(obj[relatedArr[i].replace(/\s/g, "_")].trim()); // push the related word as single word into the list arr and trim for extra spaces
  }

  // filter out the related word from the list items arr and return a new arr with the filtered list items and the arr of related words
  return [
    ...listItems.filter(
      (word) => !relatedArr.join(" ").includes(word.toLowerCase())
    ),
    ...list,
  ];
}

function getProductItems(items, spec) {
  switch (spec.toLowerCase()) {
    case "1kva":
      return joinRelatedWords(
        getWords(items, "laptop phone internet modem fan light bulbs."),
        ["internet modem", "light bulbs"]
      );
    case "1.7kva":
      return joinRelatedWords(
        getWords(items, "fans laptops phones internet modems tvs"),
        ["internet modems"]
      );
    case "2.5kva":
      return joinRelatedWords(
        getWords(items, "tvs laptops phones internet modems water dispenser"),
        ["internet modems", "water dispenser"]
      );
    case "3.5kva":
      return joinRelatedWords(
        getWords(items, "phones hps inverter ac laptops bulbs water dispenser"),
        ["hps inverter ac", "water dispenser"]
      );
    case "5kva":
      return joinRelatedWords(
        getWords(items, "laptops tvs hps inverter ac phones water dispenser"),
        ["hps inverter ac", "water dispenser"]
      );
    case "7.5kva":
      return joinRelatedWords(
        getWords(items, "water dispenser laptops phones hps inverter ac fans"),
        ["hps inverter ac", "water dispenser"]
      );
    case "10kva":
      return joinRelatedWords(
        getWords(items, "fridge freezer phones light bulbs water dispenser"),
        ["light bulbs", "water dispenser"]
      );
  }
}

const getProductPrice = (price) => {
  const { formattedCurrency } = useFormatFiguresToCurrency(price);

  return formattedCurrency.value;
};

const getProductById = () => {
  isLoading.value = true;

  axios
    .get(
      `${process.env.VUE_APP_GREEN_ENERGY_API_URL}Product/GetWebProductById?key=usdsfv9hdvv9090d90u09sdvj09&ProductId=${productToview?.id}&ComponentIdToRemove=${productToview?.componentIdToSwitch}`
    )
    .then((data) => {
      const desc = data.data.product.description.split("|")[0].split(" ");
      desc.pop();
      const spec = desc.splice(0, 2);
      productToview = {
        productName: data.data.product.name,
        warranty: data.data.product.warrantyPeriod,
        description: data.data.product.description,
        contributionPercentage: data.data.product.contributionPecentage,
        id: data.data.product.id,
        totalPrice: data.data.product.oneOffPaymentCost,
        initialPayment: data.data.product.initialPayment,
        repaymentAmount: data.data.product.repaymentAmount,
        specification: spec.join(" "),
        showDescription: false,
        components: data.data.productComponents,
        items: getProductItems(
          data.data.product.description
            .split("|")?.[1]
            ?.trim()
            ?.replace(/\s/g, ",")
            ?.split("(")?.[0]
            ?.split(",")
            ?.filter((word) => word !== "")
            ?.join(","),
          spec[0]
        ),
        componentIdToSwitch: data.data.componentIdToSwitch,
      };
      isLoading.value = false;
    })
    .catch((e) => {
      console.log("err", e);
      isLoading.value = false;
    });
};

watch(props, () => {
  if (props.product) {
    productToview = props.product;
  }
});

watch(switchBatteryType, () => {
  getProductById();
});
</script>
<style scoped>
.toggle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}
.toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.toggle label:before {
  content: "";
  width: 65px;
  height: 35px;
  position: relative;
  display: inline-block;
  background: #fff;
  border-radius: 46px;
  border: 2px solid #3a40d0;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}
.toggle label:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border: 2px solid #3a40d0;
  box-shadow: 0 0 0 2px #fff inset;
  border-radius: 50%;
  left: 6px;
  top: 4px;
  z-index: 2;
  background: #3a40d0;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}
.toggle input[type="checkbox"]:checked + label:before {
  background: #3a40d0;
}
.toggle input[type="checkbox"]:checked + label:after {
  left: 32px;
  border-color: #fff;
  box-shadow: 0 0 0 2px #3a40d0 inset;
  background: #fff;
}
</style>